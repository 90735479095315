<template>
    <div class="relative flex flex-col" :class="!isMobile && 'h-full'" ref="viewContainer">
        <div 
            class="file_list grow overflow-hidden flex flex-col"
            :class="isMobile ? 'mobile' : 'h-full'">
            <div class="file_list_header">
                <span class="file_name">
                    Название
                </span>  
                <span v-if="!isMobile" class="file_desc px-2">
                    Описание
                </span>
                <span class="file_size px-2">
                    Размер
                </span>
                <span class="file_check_all">
                    <a-checkbox 
                        :checked="checkAll"
                        ref="globalCheckbox"
                        @change="switchGlobalCheckbox" />
                </span>
            </div>
            <div class="list_scroller_wrap grow" :class="!isMobile && 'h-full'">
                <RecycleScroller
                    class="scroller h-full"
                    :class="attaching || widgetEmbed ? 'attaching_scroller' : ''"
                    :items="fileList"
                    size-field="height"
                    :pageMode="isMobile || attaching"
                    :buffer="200"
                    :item-size="46"
                    key-field="id">
                    <template #default="{ item, index }">
                        <FileDropdown
                            :ref="`file_dropdown_${item.id}`"
                            :key="`${ index }-${ item.id }`"
                            :file="item"
                            :removeFiles="removeFiles"
                            :restoreFiles="restoreFiles"
                            :setCurrentSource="setCurrentSource"
                            :attachingRootId="attachingRootId"
                            :attachingSourceId="attachingSourceId"
                            :getDropContainer="getDropContainer"
                            :isFounder="isFounder"
                            :isStudent="isStudent"
                            :rootId="rootId"
                            :sourceId="sourceId"
                            :isMyFiles="isMyFiles"
                            :isTrash="isTrash">
                            <template v-slot:fileItem>
                                <FileListItem 
                                    :file="item"
                                    :setCurrentSource="setCurrentSource"
                                    :rootId="rootId"
                                    :mobileApp="mobileApp"
                                    :sourceId="sourceId"
                                    :fileOpenSwitch="fileOpenSwitch"
                                    @switchCheckbox="onChildCheckboxSwitch"
                                    :selectedFiles="selectedFiles"
                                    :cuttedFiles="cuttedFiles" />
                            </template>
                        </FileDropdown>
                    </template>
                    <template #after>
                        <slot name="infiniteLoading"></slot>
                    </template>
                </RecycleScroller>
                <!--<template v-if="isMobile">
                    <slot name="infiniteLoading"></slot>
                </template>-->
            </div>
        </div>
    </div>
</template>

<script>
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'
import { RecycleScroller } from 'vue-virtual-scroller'

import myRolesProps from '../../mixins/myRolesProps'
import fileSourcesProps from '../../mixins/fileSourcesProps'
import fileViewsProps from '../../mixins/fileViewsProps'
import attachingSourcesProps from '../../mixins/attachingSourcesProps'
import fileActions from '../../mixins/fileActions'

import FileListItem from './FileListItem.vue'
import FileCreate from '../FileCreate.vue'
import FileDropdown from '../FileDropdown.vue'

export default {
    mixins: [myRolesProps, fileSourcesProps, fileViewsProps, attachingSourcesProps, fileActions],
    components: {
        FileListItem,
        FileDropdown,
        RecycleScroller
    },
    data() {
        return {
            checkAll: false
        }
    },
    props: {
        removeFiles: {
            type: Function,
            default: () => {}
        },        
        showFileCreate: {
            type: Boolean,
            default: true
        },
        selectedFiles: {
            type: Object,
            default: () => {}
        },
        cuttedFiles: {
            type: Object,
            default: () => {}
        },
        isSearch: {
            type: Boolean,
            default: false
        },

        isTrash: {
            type: Boolean,
            default: false
        },

        restoreFiles: {
            type: Function,
            default: () => {}
        },
        attaching: {
            type: Boolean,
            default: false
        },
        widgetEmbed: {
            type: Boolean,
            default: false
        },
        oneUpload: {
            type: Boolean,
            default: false
        },
        createFounder: {
            type: Boolean,
            default: true
        },
        mobileApp: {
            type: Boolean,
            default: false
        },
        fileDragCreate: {
            type: Boolean,
            default: true
        }
    },
    computed: {
        isMobile() {
            return this.$store.state.isMobile
        }
    },
    methods: {
        getDropContainer() {
            return this.$refs.viewContainer
        },
        switchGlobalCheckbox(event) {
            const isChecked = event.target.checked
            this.selectedFiles.from = this.sourceId
            this.selectedFiles.list.splice(0)
            if(isChecked) {
                this.selectedFiles.list.push(...this.fileList)
                this.checkAll = true
            } else {
                this.checkAll = false
            }
        },
        onChildCheckboxSwitch() {
            if(this.fileList.length === this.selectedFiles.list.length)
                this.checkAll = true
            else 
                this.checkAll = false
            // if(!this.selectedFiles.list.length) {
            //     this.checkAll = false
            // }
                

        }
    },
}
</script>

<style scoped lang="scss">

.scroller {
    min-height: 100px;
    &.attaching_scroller{
        max-height: 450px;
    }
}
.file_list {
    min-height: 80px;
    &.mobile{
        .file_list_header{
            background: #f4f7f7;
        }
    }
    &:not(.mobile){
        border: 1px solid var(--borderColor);
        border-radius: var(--borderRadius);
    }
    .file_list_header {
        display: grid;
        grid-template-columns: 1fr 1fr 80px 20px;

        padding: 8px;

        font-size: 14px;
        font-weight: 500;
        color: var(--text1);

        border-bottom: 1px solid var(--borderColor);
    }
    &.mobile {
        .file_list_header {
            grid-template-columns: 2fr 1fr 20px;
        }
    }
}
</style>